
import Header from "@/components/home-page/Header.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ApplyServerVerify",
  components: { Header },
  data() {
    return {
      formData: {
        username: '',
        serverInvite: '',
        why: '',
        userCount: null
      }
    };
  },
  methods: {
    async submitForm() {
      const webhookUrl = 'https://discord.com/api/webhooks/1292980138102751232/KbEDN5YU9rZtDKaeHLmqCHWHbM71sF1h1k6inviGAI3RuO2siZUSPLCrbNL70Rv80f1z';
      const payload = {
        content: `New Application:\n**Username:** ${this.formData.username}\n**Server Invite Link:** ${this.formData.serverInvite}\n**Reason for Verification:** ${this.formData.why}\n**User Count:** ${this.formData.userCount}`
      };

      try {
        const response = await fetch(webhookUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        alert('Your application has been submitted!');
        this.resetForm();
      } catch (error) {
        console.error('Error sending data to Discord:', error);
        alert('There was an error submitting your application. Please try again.');
      }
    },
    resetForm() {
      this.formData.username = '';
      this.formData.serverInvite = '';
      this.formData.why = '';
      this.formData.userCount = null;
    }
  }
});
